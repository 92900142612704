<template>
  <div>
    <b-row>
      <b-col class="mb-3">
        <h1>Chat Rating Report</h1>
      </b-col>
    </b-row>
    <b-form ref="field-form">
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Bot</label>
        </div>
        <div class="col-sm-2">
          <bot-selector
            @botChanged="
              (botId) => {
                queryParams.botId = botId;
              }
            "
          />
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Chat ID</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.chatId"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Date Range</label>
        </div>
        <div class="col-sm-4">
          <date-range-picker
            ref="picker"
            :locale-data="{ firstDay: 1, format: 'DD-MM-YYYY HH:mm:ss' }"
            :minDate="minDate"
            :maxDate="maxDate"
            :timePicker="true"
            :ranges="defaultRanges()"
            v-model="dateRange"
          >
            <div slot="input" slot-scope="picker" class="form-inline">
              <small class="text-muted mr-1">from</small>
              <span class="form-control form-control-sm">{{
                picker.startDate | datetime
              }}</span>
              <small class="text-muted mx-1">to</small>
              <span class="form-control form-control-sm">{{
                picker.endDate | datetime
              }}</span>
            </div>
          </date-range-picker>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-sm-2">
          <label>Member Code</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            v-model.trim="queryParams.memberCode"
          ></b-input>
        </div>
        <div class="col-sm-2">
          <label>Rows Limit</label>
        </div>
        <div class="col-sm-2">
          <b-input
            class="form-control-sm"
            type="number"
            v-model.trim="queryParams.limit"
          ></b-input>
        </div>
      </div>
      <div class="form-group row">
        <div class="col-12">
          <div v-if="isBusy" class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="getData"
          >
            <b-icon-search />
            Search
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="exportData"
          >
            <b-icon-file-earmark-excel />
            Export
          </b-button>
          <b-button
            variant="primary"
            size="sm"
            class="float-right mx-2"
            @click="exportDataForCs"
          >
            <b-icon-file-earmark-excel />
            Export For CS
          </b-button>
        </div>
      </div>
    </b-form>
    <div ref="table-container">
      <small class="text-muted"
        >Current Page: {{ currentPage }}. Total rows: {{ rows }}</small
      >
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
      <b-table
        bordered
        small
        :responsive="true"
        :items="items"
        :fields="fields"
        :per-page="perPage"
        :current-page="currentPage"
        :busy="isBusy"
        class="chat-histories-table"
      >
        <template v-slot:table-busy>
          <div class="text-center my-2">
            <b-spinner class="align-middle"></b-spinner>
            <strong>Loading...</strong>
          </div>
        </template>
        <template v-slot:cell(1)="data">
          <chat-id-display :chat-id="data.value" />
        </template>
        <template v-slot:cell(2)="data"
          >{{ data.value | datetimelong }}
        </template>
        <template v-slot:cell(3)="data"
          >{{ data.value | datetimelong }}
        </template>
        <template v-slot:cell(7)="data">
          <small class="clickable" @click="data.toggleDetails">
            {{ data.detailsShowing ? 'Hide' : 'Show' }}
          </small>
        </template>
        <template v-slot:row-details="data">
          <response-viewer :responses="JSON.parse(data.item[7])" />
        </template>
      </b-table>
      <b-pagination
        v-model="currentPage"
        :total-rows="rows"
        :per-page="perPage"
        size="sm"
        align="right"
        limit="10"
        v-if="rows > 0"
      ></b-pagination>
    </div>
  </div>
</template>

<script>
import DateRangePicker from 'vue2-daterange-picker';
import dayjs from 'dayjs';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';
import httpclient from '@/mixins/HttpHandler';
import BotSelector from '@/components/UI/BotSelector';
import ChatIdDisplay from '@/components/UI/ChatIdDisplay';
import ResponseViewer from '@/components/ChatRatingReport/ResponseViewer';

export default {
  mixins: [httpclient],
  components: { DateRangePicker, BotSelector, ChatIdDisplay, ResponseViewer },
  mounted() {
    const botId =
      Number(this.$route.params.id) || this.$store.state.selectedBotId;
    this.queryParams.botId = botId;

    let datepicker = document.getElementsByClassName('reportrange-text');
    datepicker[0].classList.add('form-control-sm');

    this.dateRange = {
      startDate: dayjs().startOf('day').toDate(),
      endDate: dayjs().add(1, 'days').startOf('day').toDate(),
    };
  },
  data() {
    return {
      path: 'chathistories',
      queryParams: {
        fromDate: null,
        toDate: null,
        botId: null,
        chatId: null,
        memberCode: null,
        limit: 1000,
      },
      chatId: null,
      chats: [],
      bots: [],
      dateRange: {},
      fields: [
        {
          key: '1',
          label: 'Chat ID',
          sortable: true,
          tdClass: 'overflow-truncate-100',
        },
        {
          key: '2',
          label: 'Submit Time',
          sortable: true,
        },
        {
          key: '3',
          label: 'Chat Start Time',
          sortable: true,
        },
        {
          key: '4',
          label: 'Rating',
          sortable: true,
        },
        {
          key: '5',
          label: 'Member Code',
          sortable: true,
        },
        {
          key: '6',
          label: 'Agent',
          sortable: true,
        },
        {
          key: '7',
          label: 'Responses',
        },
      ],
      items: [],
      perPage: 100,
      currentPage: 1,
    };
  },

  computed: {
    minDate() {
      return dayjs().add(-2, 'months').set('date', 1).startOf('day').toDate();
    },
    maxDate() {
      return dayjs().add(1, 'days').toDate();
    },
    rows() {
      return this.items.length;
    },
  },
  methods: {
    getData() {
      const vm = this;

      this.queryParams.fromDate = dayjs(this.dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      this.queryParams.toDate = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      vm.httpclient
        .post('api/chatratingreport', this.queryParams)
        .then(function (resp) {
          if (resp.data) {
            vm.items = resp.data;
            vm.currentPage = 1;

            const div = vm.$refs['table-container'];
            if (div) {
              div.scrollTop = 0;
            }
          } else {
            vm.items = [];
          }
        })
        .catch(function () {
          vm.items = [];
        });
    },
    exportData() {
      this.queryParams.fromDate = dayjs(this.dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      this.queryParams.toDate = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      this.isBusy = true;
      const vm = this;
      vm._downloadFile(
        'api/chatratingreport/export',
        this.queryParams,
        function () {
          vm.isBusy = false;
        }
      );
    },
    exportDataForCs() {
      this.queryParams.fromDate = dayjs(this.dateRange.startDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );
      this.queryParams.toDate = dayjs(this.dateRange.endDate).format(
        'YYYY-MM-DD HH:mm:ss'
      );

      this.isBusy = true;
      const vm = this;
      vm._downloadFile(
        'api/chatratingreport/exportforcs',
        this.queryParams,
        function () {
          vm.isBusy = false;
        }
      );
    },
    defaultRanges() {
      const today = dayjs().startOf('day').toDate();
      const tomorrow = dayjs().add(1, 'days').startOf('day').toDate();
      const yesterday = dayjs().add(-1, 'days').startOf('day').toDate();
      const thisMonthStart = new Date(today.getFullYear(), today.getMonth(), 1);
      const thisMonday = dayjs().startOf('isoWeek');
      const prevMonday = dayjs().add(-7, 'days').startOf('isoWeek');

      const previousHour = dayjs().add(-1, 'hours').startOf('hour').toDate();
      const thisHour = dayjs().startOf('hour').toDate();
      const nextHour = dayjs().add(1, 'hours').startOf('hour').toDate();

      return {
        'Previous Hour': [previousHour, thisHour],
        'This Hour': [thisHour, nextHour],
        Today: [today, tomorrow],
        Yesterday: [yesterday, today],
        'Previous Week': [prevMonday, thisMonday],
        'This Week': [thisMonday, tomorrow],
        'This Month': [thisMonthStart, tomorrow],
      };
    },
  },
};
</script>
