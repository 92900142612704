<template>
  <b-list-group flush>
    <response-viewer-item
      v-for="response in responses"
      :key="response.Name"
      :response="response"
      class="mr-2"
    />
    <!--    <b-badge-->
    <!--      :variant="response.Name === 'Rating' ? 'primary' : 'secondary'"-->
    <!--      v-for="response in responses"-->
    <!--      :key="response.Name"-->
    <!--      class="mr-2"-->
    <!--    >-->
    <!--      {{ response.Name }} : {{ response.Value }}-->
    <!--    </b-badge>-->
  </b-list-group>
</template>

<script>
import ResponseViewerItem from '@/components/ChatRatingReport/ResponseViewerItem';

export default {
  name: 'ResponseViewer',
  components: { ResponseViewerItem },
  props: {
    responses: Array,
  },
};
</script>

<style scoped></style>
