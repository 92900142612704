<template>
  <b-list-group-item>
    <b-row>
      <b-col cols="3" xl="2">
        <small class="font-weight-bold mr-2">{{ response.Name }} :</small>
      </b-col>
      <b-col>
        <b-card
          v-if="_isImageFile(file.originalFileName)"
          :img-src="file.signedUrl"
          img-alt="Card image"
          img-bottom
          overlay
          class="response-viewer mt-2"
        >
          <b-card-text>
            <span class="image-background"
              >{{ response.Name }} :
              <a
                :href="file.signedUrl"
                target="_blank"
                @click.prevent.stop="
                  downloadFile(file.signedUrl, file.originalFileName)
                "
              >
                {{ file.originalFileName }}
              </a></span
            >
          </b-card-text>
        </b-card>
        <b-badge v-else-if="response.Name === 'Rating'" variant="info">
          {{ response.Value }}
        </b-badge>
        <small v-else class="d-inline-block">{{ response.Value }}</small>
      </b-col>
    </b-row>

    <!--    <b-card-->
    <!--      v-if="_isImageFile(file.originalFileName)"-->
    <!--      :img-src="file.signedUrl"-->
    <!--      img-alt="Card image"-->
    <!--      img-bottom-->
    <!--      overlay-->
    <!--      class="response-viewer mt-2"-->
    <!--    >-->
    <!--      <b-card-text>-->
    <!--        <span class="image-background"-->
    <!--          >{{ response.Name }} :-->
    <!--          <a-->
    <!--            :href="file.signedUrl"-->
    <!--            target="_blank"-->
    <!--            @click.prevent.stop="-->
    <!--              downloadFile(file.signedUrl, file.originalFileName)-->
    <!--            "-->
    <!--          >-->
    <!--            {{ file.originalFileName }}-->
    <!--          </a></span-->
    <!--        >-->
    <!--      </b-card-text>-->
    <!--    </b-card>-->
    <!--    <b-badge-->
    <!--      v-else-->
    <!--      :variant="response.Name === 'Rating' ? 'primary' : 'secondary'"-->
    <!--      class="response-viewer"-->
    <!--      :title="response.Value"-->
    <!--    >-->
    <!--      {{ displayResponseData(response) }}-->
    <!--    </b-badge>-->
  </b-list-group-item>
</template>

<script>
import _ from 'lodash';
import httpHandler from '@/mixins/HttpHandler';

export default {
  name: 'ResponseViewerItem',
  props: {
    response: Object,
  },
  mixins: [httpHandler],
  mounted() {
    this.loadFileUrl();
  },
  watch: {
    response() {
      this.loadFileUrl();
    },
  },
  data() {
    return {
      file: {},
      isDownloading: false,
    };
  },
  methods: {
    loadFileUrl() {
      this.file = _defaultFile();
      const response = _.cloneDeep(this.response);
      if (response.Type && response.Type === 'imageUpload') {
        const fileUploadId = response.Value;
        if (!isNaN(parseInt(fileUploadId))) {
          this.httpclient
            .get(`api/fileUploads/${fileUploadId}/get-url`)
            .then((resp) => {
              this.file = resp.data;
            });
        }
      }
    },
    downloadFile(url, fileName) {
      this.isDownloading = true;
      this._downloadFileWithBlob(url, fileName).then(() => {
        this.isDownloading = false;
      });
    },
    displayResponseData(response) {
      let displayString = `${response.Name}: ${response.Value}`;
      if (response.Type === 'imageUpload')
        displayString = `${response.Name}: ${this.file.originalFileName}`;
      return displayString;
    },
  },
};

function _defaultFile() {
  return { signedUrl: '', originalFileName: '', contentType: '' };
}
</script>

<style scoped>
.image-background {
  background-color: #0a0a0ab3;
  border-radius: 5px;
  padding: 2px;
  font-size: 0.8em;
  color: #ffffff;
  position: absolute;
  top: 5px;
  left: 5px;
}

.response-viewer {
  display: inline-block;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: break-spaces;
  max-width: calc(100% - 10px);
}
</style>
