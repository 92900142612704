<template>
  <div class="d-inline">
    <span class="clickable" @click="searchChatId">{{ chatId }}</span>
    <b-modal
      size="lg"
      scrollable
      hide-footer
      ref="chat-modal"
      header-class="chat-id-header"
      lazy
    >
      <template v-slot:modal-title>
        <b-overlay :show="copiedOverlay" variant="success" no-center rounded>
          <template #overlay>
            <span
              class="d-block text-left text-light pl-3 pt-1"
              style="font-size: 0.8em"
              >Copied ...</span
            >
          </template>
          <span
            class="d-inline-block text-truncate chat-id-display"
            v-b-tooltip.hover
            :title="'Click to copy: ' + chatId"
            @click="selectChatId"
          >
            Chat ID: {{ chatId }}
          </span>
        </b-overlay>
      </template>
      <chat-text :chats="chats" />
    </b-modal>
  </div>
</template>

<script>
import httpHandler from '@/mixins/HttpHandler';
import ChatText from '@/components/ChatHistories/ChatText';

export default {
  name: 'ChatIdDisplay',
  mixins: [httpHandler],
  components: { ChatText },
  props: {
    chatId: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      chats: [],

      copiedOverlay: false,
    };
  },
  methods: {
    searchChatId() {
      let chatId = this.chatId;

      this.httpclient
        .post('api/chathistories/getlist', { chatId: chatId, limit: 1000 })
        .then((resp) => {
          if (this.chatId !== chatId) return;

          this.chats = resp.data;
          this.$refs['chat-modal'].show();
        });
    },
    selectChatId() {
      navigator.clipboard.writeText(this.chatId);
      this.copiedOverlay = true;
      setTimeout(() => {
        this.copiedOverlay = false;
      }, 1000);
    },
  },
};
</script>

<style scoped>
.chat-id-display {
  cursor: pointer;
  width: 260px;
}

@media (min-width: 576px) {
  .chat-id-display {
    width: 420px;
  }
}

@media (min-width: 768px) {
  .chat-id-display {
    width: 420px;
  }
}

@media (min-width: 992px) {
  .chat-id-display {
    width: 720px;
  }
}

@media (min-width: 1200px) {
  .chat-id-display {
    width: 720px;
  }
}
</style>
